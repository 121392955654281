<template>
  <div v-if="detail">
    <div class="crumbs">
      <router-link to="/a-company/company-preserve">首页</router-link>
      <label>></label>
      <a href="###">企业详情</a>
    </div>
    <div class="top-row">
      <div class="top-left">
        <div class="company-picture">
          <img :src="detail.logo" alt="" />
        </div>
        <div class="company-base-content">
          <div>公司代码：{{ detail.code }}</div>
          <div>{{ detail.name }}</div>
          <div class="tag-row">
            <div class="tag-item">
              {{ { 0: "集成类", 1: "供货方", 2: "招标方" }[detail.type] }}
            </div>
          </div>
        </div>
      </div>
      <div class="top-right">
        <div>会员到期日</div>
        <div>{{ $dayjs(detail.vipEndTime).format("YYYY-MM-DD") }}</div>
        <div style="text-align: right">
          <el-link
            type="primary"
            :underline="false"
            @click="$router.push('/a-company/company/member/view')"
            >立即续费</el-link
          >
        </div>
      </div>
    </div>

    <div class="border-wrap-box">
      <el-form size="small" label-width="100px" label-position="left">
        <el-form-item label="手机号码">{{ detail.bindPhone }}</el-form-item>
        <el-form-item label="公司资质">
          {{ detail.sysIntegration }}
        </el-form-item>
        <el-form-item label="年营业额"
          >{{ detail.salse | currency }} 万元</el-form-item
        >
        <el-form-item label="年采购额"
          >{{ detail.purchase | currency }} 万元</el-form-item
        >
        <el-form-item label="年投标规模"
          >{{ detail.bidAmount | currency }} 万元</el-form-item
        >
        <el-form-item label="企业介绍">{{ detail.memo }}</el-form-item>
      </el-form>
      <div class="btn-row">
        <el-button
          type="primary"
          size="small"
          class="btn-row-item"
          @click="goUpdate"
          >修改</el-button
        >
        <el-button size="small" class="btn-row-item" @click="cancel"
          >返回</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: null,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let code = this.$route.params.code;
      this.$axios.get(`/member/selectMemberDetail/${code}`).then((res) => {
        if (res.state == 200) {
          this.detail = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    goUpdate() {
      let code = this.$route.params.code;
      this.$router.push(`/a-company/company-preserve/update/${code}`);
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.top-row {
  display: flex;
  .top-left {
    border: 1px solid #ededed;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
    box-sizing: border-box;
    .company-picture {
      width: 123px;
      height: 123px;
      background: #ddd;
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }
    .company-base-content {
      height: 100%;
      flex: 1;
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tag-row {
        display: flex;
        justify-content: space-between;
        .tag-item {
          background: #004375;
          color: #ffffff;
          padding: 4px 12px;
        }
      }
    }
  }
  .top-right {
    margin-left: 24px;
    width: 253px;
    height: 187px;
    // background: linear-gradient(180deg, #4e8fff 0%, #2854ed 100%);

    border: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    // color: #ffffff;
    padding: 34px 31px;
    box-sizing: border-box;
  }
}

.border-wrap-box {
  margin-top: 24px;
  padding: 64px;
  box-sizing: border-box;
  border: 1px solid #ededed;
  margin-bottom: 20px;

  .tip-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    .el-icon-question {
      color: #fa6400;
      margin-right: 5px;
    }
  }
  .btn-row {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-row-item {
      width: 151px;
    }
  }
}
</style>